
import {Component, Mixins, Ref} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {PersonelPermissionEntity} from "@/entity/PersonelPermissionEntity";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import {StatusCodeHelper} from "@/helpers/StatusCodeHelper";
import {BildirimEntity} from "@/entity/BildirimEntity";

@Component({
  components: {DeleteDialog, FormDialog, FormWrapper}
})
export default class KullaniciIzinleriForm extends Mixins(ObjectInputMixin) {
  @Ref('formWrapper') formWrapper!: FormWrapper;

  ready = false;
  items: Array<PersonelPermissionEntity> = [];
  allData: Array<PersonelPermissionEntity> = [];
  butunIzinler: Array<string> = [];
  selectedCheckbox=false
  allSelected = false; // New state variable to track selection state


  get rootDirs(): Array<PersonelPermissionEntity> {
    return this.items.filter((value) => value.parent === null)
  }

  mounted() {
    this.ready = false;
    this.load();
  }
  onClose(){
    this.selectedCheckbox = false
    this.$emit("onClose");

  }
  selectAll() {

    if (this.allSelected) {
      this.localValue.permissions = [];
    } else {
      this.localValue.permissions = [...this.butunIzinler];
    }
    this.allSelected = !this.allSelected;
    this.input();
  }

  async load() {
    await this.getPermissions();
    if (this.localValue.id) {
      await this.loadRolePermissions();
    }
    this.ready = true;
  }

  async getPermissions() {
    let response = await this.$http.get("/api/v1/permissions");
    let permissions = response.data;
    permissions.forEach((permission: any) => {
      let data: PersonelPermissionEntity = new PersonelPermissionEntity();
      data.id = permission.id;
      data.name = permission.name;
      data.label = permission.label;
      data.children = [];
      data.parent = null;
      this.allData.push(data);
      if (permission.parent_id) {
        this.allData.forEach((val) => {
          if (permission.parent_id === val.id) {
            data.parent = val;
          }
        })
      }
      this.items.push(data);
    });
    this.items.filter((value, index) => value.parent !== null).forEach((value, index) => {
      let parent = this.items.filter(value1 => value1.id === value.parent?.id)[0]
      if (!parent.children) {
        parent.children = [];
      }
      parent.children.push(value);
    });
    let izinler:any = [];
    this.items.forEach((item) => (izinler.push(item.name)));
    this.butunIzinler = [...izinler];

  }

  async loadRolePermissions() {
    let response = await this.$http.get("/api/v1/roles/" + this.localValue.id + "/permissions");
    let permission: Array<string> = [];
    response.data.forEach((item: any) => {
      permission.push(item.name);
    })
    this.localValue.permissions = permission;
    this.input();

  }

  protected async onValueChange() {
    if (this.localValue.permissions) {
      this.localValue.permissions = this.localValue.permissions.map((value: any) => value.name);
    }
  }

  async save() {
    this.formWrapper.onFly = true;
    if (this.localValue.id) {
      await this.$http.put("/api/v1/roles/" + this.localValue.id, this.localValue);
      await this.loadRolePermissions();
      // bildirim infoda kullanıcıyı güncellemek için lazım
      let roldekiKullanicilar = (await this.$http.get("/api/v1/personel/filter?roleId=" + this.localValue.id)).data;
      //@ts-ignore
      let bildirim: BildirimEntity = {
        baslik: 'İzinler Güncellendi',
        aciklama: this.$store.state.user.first_name + ' ' + this.$store.state.user.last_name + ' kullanıcı izinlerinde değişiklik yaptı.'
      }
      for (const roldekiKullanici of roldekiKullanicilar) {
        bildirim.user_id = roldekiKullanici.id;
        await this.$http.post('/api/v1/personel/' + roldekiKullanici.id + '/bildirim', bildirim).catch((error) => StatusCodeHelper.error(error));
      }
      this.formWrapper.onFly = false;
    } else
      await this.$http.post("/api/v1/roles", this.localValue);
    await this.$emit("onSuccess");
    this.formWrapper.onFly = false;
  }
}
