
import {Component, Mixins, Vue} from "vue-property-decorator";
import KullaniciIzinleriForm from "@/components/forms/KullaniciIzinleriForm.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";
import SayfaAciklama from "@/components/SayfaAciklama.vue";

@Component({
  components: {SayfaAciklama, KullaniciIzinleriForm, DeleteDialog, FormDialog, KullaniciIzinleriList: KullaniciIzinleriForm}
})
export default class PersonelTurleriList extends Mixins(ObjectInputMixin) {
  personelTipleri = [];
  checkboxOfis= false
  checkboxIcra = false
  checkboxEicra = false
  checkboxDava = false
  checkboxArabulucu = false
  permissions = []

  headers = [
    {text: "Personel Türleri", value: "personel_turu"},
    {text: "İşlemler", value: "actions", sortable: false, align: "end"}
  ]

  mounted() {
    this.load();
  }

  load() {
    this.$http.get("/api/v1/roles").then((response) => {
      this.personelTipleri = response.data;
    });
  }
}
