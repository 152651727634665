
import {Component, Vue} from "vue-property-decorator";
import PersonelTurleriList from "@/components/lists/PersonelTurleriList.vue";

@Component({
  components: { PersonelTurleriList }
})
export default class OfisTercihlerView extends Vue {

}
